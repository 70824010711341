import React from "react";

class TruckInfo extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            truckInfo: this.props.truckInfo || {}
        };

    }

    render () {
        console.log(this.state);
        return (
            <div className="truck-info">
                <p> truck id - {this.state.truckInfo.id ? this.state.truckInfo.id : 'No truck selected!'}</p>
            </div>
        )
    }
}

export default TruckInfo;