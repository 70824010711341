import React from "react";

class MainMap extends React.Component {
    constructor (props) {
        super(props);
        this.state = {

        };

        this.map = {};
        this.markersOnMap = {};
    }

    componentDidMount() {

        this.state = {
            mapData: this.props.mapData,
            mapMarkers: this.props.mapMarkers
        };

        this.mapInit();
        // this.addMarkers(this.state.mapMarkers);
        console.log(this.state);
    }

    addMarkers(data) {

        if (data.length < 1) {
            return;
        }

        let markerData = [];
        let center =  [data[0].lng, data[0].ltd];

        for (let i = 0, l = data.length; i < l; i++) {

            let point = data[i];

            if (i === 0) {
                center = [point.lng, point.ltd];
            }


            this.addMarker(point);

        }

        this.map.setCenter(center);


    }

    addMarker(markerInfo) {

        // this.removeMarkers();
        markerInfo = this.createMarkerData(markerInfo);


        if (markerInfo.id in this.markersOnMap) {

            this.map.setCenter(this.markersOnMap[markerInfo.id].getLngLat());

        } else {

            let marker = document.createElement('div'),
                popUpHTML = '';
            marker.className = 'marker';

            popUpHTML += '<h3>' + markerInfo.title + '</h3>';
            popUpHTML += '<p>' + markerInfo.content+ '</p>';

            // make a marker for each feature and add to the map
            this.markersOnMap[markerInfo.id] = new mapboxgl.Marker(marker)
                .setLngLat(markerInfo.geometry.coordinates)
                .setPopup(
                    new mapboxgl.Popup({ offset: 15 })
                        .setHTML(popUpHTML)
                )
                .addTo(this.map);

            this.map.setCenter(this.markersOnMap[markerInfo.id].getLngLat());

        }


        this.props.onMarkerAdded(this.markersOnMap);

        this.map.setZoom(9);

    }

    createMarkerData(point) {
        return {
            id: point.id,
            type: 'Feature',
            content: point.content,
            title: point.title,
            geometry: {
                type: 'Point',
                coordinates: [point.lng, point.ltd]
            },
            properties: {
                title: 'Mapbox',
                description: 'Washington, D.C.'
            }
        }
    }


    removeMarkers () {
        // remove markers from map
        for (let markerId in this.markersOnMap) {
            if (this.markersOnMap.hasOwnProperty(markerId)) {
                this.removeMarker(markerId);
            }
        }
        // remove markers from map
    }

    removeMarker (id) {
        if (id in this.markersOnMap) {
            this.markersOnMap[id].remove();
            delete this.markersOnMap[id];
        }
    }



    goToMarker(id) {
        console.log(id);

        if (this.markersOnMap[id]) {
            this.map.panTo(this.markersOnMap[id].getLngLat());
        }

    }

    mapInit() {


        mapboxgl.accessToken = 'pk.eyJ1IjoiYWxleC16diIsImEiOiJjanRqdDY1aHIxM3N3NDlwZjNjcmdxOWdwIn0.DGoyIaXT31w5HxzQj94Ang';
        this.map = new mapboxgl.Map({
            container: 'map', // container id
            style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
            center: [-97.9657422, 40.0692], // starting position [lng, lat]
            zoom: 3 // starting zoom
        });



        /*setTimeout(()=> {
            this.markersOnMap[0].setLngLat([-74.55, 40]);
        }, 5000);
        setTimeout(()=> {
            this.markersOnMap[0].setLngLat([-74.60, 40]);
        }, 6000);
        setTimeout(()=> {
            this.markersOnMap[0].setLngLat([-74.65, 40]);
        }, 7000);
        setTimeout(()=> {
            this.markersOnMap[0].setLngLat([-74.70, 40]);
        }, 8000);*/
    }

    render () {
        console.log('render Map');
        //this.addMarkers(this.props.mapMarkers);
        return (
            <div className="map-container">
                <div id="map">

                </div>
            </div>
        )
    }
}

export default MainMap;