import React from "react";
import Header from "./Header";
import LogTable from "./LogTable";

class Log extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {

        return (
            <div className="page-wrapper log-page">
                <Header match={this.props.match} />
                <div className="title">
                    Log
                </div>
                <div className="content">
                    <LogTable />
                </div>
            </div>
        )
    }
}

export default Log;