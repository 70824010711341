import React from "react";
import { Link, Route } from "react-router-dom";

class Header extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <header className="header">
                <nav className="main-nav">
                    <ul>
                        <li>
                            <Link to="/dist/" className={ this.props.match.path === '/dist/' ? 'active' : '' } >Home</Link>
                        </li>
                        <li>
                            <Link to="/dist/log.html" className={ this.props.match.path === '/dist/log.html' ? 'active' : '' }>Log</Link>
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
}

export default Header;