import React from "react";

class MapTags extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {

        let tags;
        if (this.props.tags.length > 0) {
            tags = this.props.tags.map( (marker, i) => {
                return <div className="tag-item" key={marker.id} data-marker-id={marker.id} data-num={i}>
                    <div className="tag-item__content">
                        <div className="text" onClick={this.props.tagClickHandler.bind(null, marker.id)}>
                            {marker.id}
                        </div>
                        <div className="remove-marker" onClick={this.props.removeMarker.bind(null, i)} >
                        </div>
                    </div>
                </div>;
            });
        } else {
            return ('');
        }
        return (
            <div className="marker-tags">
                {tags}
            </div>
        )
    }
}

export default MapTags;