import React from "react";
import Header from "./Header";
import App from "./App";


class Home extends React.Component {
    constructor (props) {
        super(props);

        // temp END
    }


    render () {

        return (
            <div className="page-wrapper home">
                <Header match={this.props.match} />
                <App />
            </div>
        )
    }
}

export default Home;