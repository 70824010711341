import React from "react";
import MainMap from "./MainMap";
import MapTags from "./MapTags";
import Header from "./Header";
import TruckInfo from "./TruckInfo";
import axios from "axios";


class App extends React.Component {
    constructor (props) {
        super(props);

        const trucks = [
            {
                id: '1',
                model: 'Model REAL deviceID',
                deviceId: '85A173140107',
            },
            {
                id: '2',
                model: 'Model Test1',
                deviceId: '85A173140108',
            },
            {
                id: '3',
                model: 'Model Test2',
                deviceId: '85A173140109',
            }
        ];
        this.state = {
            trucks: trucks,
            mapData: {},
            truck_id: '',
            mapMarkers: [],
            isLoading: false,
        };

        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.showTruckOnMap = this.showTruckOnMap.bind(this);
        this.onChange = this.onChange.bind(this);
        this.mapRef = React.createRef();

        this.removeMarker = this.removeMarker.bind(this);
        this.onMarkerAdded = this.onMarkerAdded.bind(this);

        this.tagClickHandler = this.tagClickHandler.bind(this);

        document.addEventListener('keypress', this.keyPressHandler.bind(this));

        // temp
        window.addEventListener('load', () => {
            this.setState({
                truck_id: '85A173140107'
            });
        })
        // temp END

    }

    keyPressHandler(e) {
        let code = e.keyCode || e.charCode || e.which;

        if (code === 13) {
            this.showTruckOnMap()
        }
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value })
    }
    showTruckOnMap () {
        //temp for anim
        // this.setState({
        //     isLoading: !this.state.isLoading
        // });
        // return;

        //temp for anim END
        this.getTruckInfo(this.state.truck_id);
    }

    selectChangeHandler (e) {
        this.getTruckInfo(e.target.value);
    }

    getTruckInfo(deviceId) {
        const tempData = {
            '85A173140107': '{ "DirectionAlpha": "N", "Duration": "0", "EventDateTime": "2019-03-25T14:40:12Z", "Format": "AC37", "Identifier": "85A173140107", "IdleDuration": 4661957.0, "IgnitionOnDuration": 12831172.0, "J1939EngineHours": 488040.0, "J1939IdleHours": 163320.0, "J1939TotalPTOHours": 0.0, "Location": { "Address": null, "CrossStreet": null, "GeoLocation": { "Altitude": 0.0, "GeodeticCell": [], "GeodeticFine": null, "Latitude": 43.11342, "Longitude": -75.20648 }, "Interpolated": false, "Named": false, "NamedLocation": null, "NamedLocationType": 0, "RegionId": "00000000-0000-0000-0000-000000000000", "RegionTitle": null }, "LocationAgeMin": "0", "Measurements": "English", "MessageReason": "IGN_OFF", "NumSatellites": "8", "OBDOdometer": 195849.6, "OBDVIN": "3AKJHHDR8JSJS6964", "Odometer": "148018.8", "OperatingStates": [ { "Id": "IgnitionOff", "Value": "12831172" } ], "PacketTimeStamp": 1553524812, "Speed": "0", "UniqueId": "164", "_id": { "$oid": "5c98e86f9b5d6c1d36638ca5" } }',
            '85A173140108': '{ "DirectionAlpha": "N", "Duration": "0", "EventDateTime": "2019-03-25T14:40:12Z", "Format": "AC37", "Identifier": "85A173140108", "IdleDuration": 4661957.0, "IgnitionOnDuration": 12831172.0, "J1939EngineHours": 488040.0, "J1939IdleHours": 163320.0, "J1939TotalPTOHours": 0.0, "Location": { "Address": null, "CrossStreet": null, "GeoLocation": { "Altitude": 0.0, "GeodeticCell": [], "GeodeticFine": null, "Latitude": 40.11342, "Longitude": -80.20648 }, "Interpolated": false, "Named": false, "NamedLocation": null, "NamedLocationType": 0, "RegionId": "00000000-0000-0000-0000-000000000000", "RegionTitle": null }, "LocationAgeMin": "0", "Measurements": "English", "MessageReason": "IGN_OFF", "NumSatellites": "8", "OBDOdometer": 195849.6, "OBDVIN": "3AKJHHDR8JSJS6964", "Odometer": "148018.8", "OperatingStates": [ { "Id": "IgnitionOff", "Value": "12831172" } ], "PacketTimeStamp": 1553524812, "Speed": "0", "UniqueId": "164", "_id": { "$oid": "5c98e86f9b5d6c1d36638ca5" } }',
            '85A173140109': '{ "DirectionAlpha": "N", "Duration": "0", "EventDateTime": "2019-03-25T14:40:12Z", "Format": "AC37", "Identifier": "85A173140109", "IdleDuration": 4661957.0, "IgnitionOnDuration": 12831172.0, "J1939EngineHours": 488040.0, "J1939IdleHours": 163320.0, "J1939TotalPTOHours": 0.0, "Location": { "Address": null, "CrossStreet": null, "GeoLocation": { "Altitude": 0.0, "GeodeticCell": [], "GeodeticFine": null, "Latitude": 42.11342, "Longitude": -85.20648 }, "Interpolated": false, "Named": false, "NamedLocation": null, "NamedLocationType": 0, "RegionId": "00000000-0000-0000-0000-000000000000", "RegionTitle": null }, "LocationAgeMin": "0", "Measurements": "English", "MessageReason": "IGN_OFF", "NumSatellites": "8", "OBDOdometer": 195849.6, "OBDVIN": "3AKJHHDR8JSJS6964", "Odometer": "148018.8", "OperatingStates": [ { "Id": "IgnitionOff", "Value": "12831172" } ], "PacketTimeStamp": 1553524812, "Speed": "0", "UniqueId": "164", "_id": { "$oid": "5c98e86f9b5d6c1d36638ca5" } }'
        };


        let error = false;

        if (this.state.isLoading || deviceId === '0' || !tempData[deviceId]) {
            error = true;
        }

        if (this.state.mapMarkers.length > 0) {
            this.state.mapMarkers.forEach(function (marker) {
                if (deviceId === marker.id) {
                    error = true;
                }
            })
        }

        if (error) {return} // stop if error

        this.setState({
            isLoading: true
        });

        let data = {};

        try {
            data = JSON.parse(tempData[deviceId])
        } catch (e) {
            console.warn('error in JSON data ' + e);
        }

        this.onTruckInfoDataReceive(data);

        return;

        axios({
            method: 'get',
            url: 'https://data.fleet-tec.com:65480/device/geometris/json/' + deviceId,
        }).then((response) => {
            console.log(response);
        }).catch(function (error) {
            console.log(error);
        });
    }

    convertToMarkerData(data) {

        let convertedData = [];


        convertedData.push(
            {
                id: data.Identifier,
                lng: data.Location.GeoLocation.Longitude,
                ltd: data.Location.GeoLocation.Latitude,
                title: data.Identifier,
                content: 'html content ' + data.Identifier,
            }
        );

        return convertedData;
    }



    onTruckInfoDataReceive (data, id) {


        const markerData = this.convertToMarkerData(data);



        //TODO тригерить APP tags при добавлении маркера

        console.log(markerData);

        this.mapRef.current.addMarker(markerData[0]);

    }

    tagClickHandler (markerId, e) {
        //let id = e.target.parentElement.parentElement.getAttribute('data-marker-id');
        this.mapRef.current.goToMarker(markerId)
    }

    onMarkerAdded (markers) {

        const markersOnMap = [];

        console.log(markers, ' marker ADDED');

        for (let id in markers) {
            if (markers.hasOwnProperty(id)) {
                markersOnMap.push({
                    id: id
                });
            }
        }

        this.setState({
            mapMarkers: markersOnMap
        });

        setTimeout(() => this.setState({
            isLoading: false
        }), 500);
    }

    removeMarker(tagIndex, e) {
        /*let id = e.target.parentElement.parentElement.getAttribute('data-marker-id');
        let num = e.target.parentElement.parentElement.getAttribute('data-num');
        this.mapRef.current.removeMarker(id);
        this.markersOnMap.splice(num, 1);*/

        if (this.state.mapMarkers[tagIndex]) {
            this.mapRef.current.removeMarker(this.state.mapMarkers[tagIndex].id);
            this.setState({
                mapMarkers: this.state.mapMarkers.filter((_,i) => tagIndex !== i)
            });
        }
    }


    render () {
        let selectOptions = this.state.trucks.map( (truck) => {
            return <option key={truck.id} value={truck.deviceId} data-truck-id={truck.id}> {truck.model} </option>
        });

        return (
            <div className="app-container">
                <div className="left-aside">

                    <div className="title">
                        Enter Truck ID
                    </div>
                    <select className="truck-select" name="" id="" onChange={this.selectChangeHandler}>
                        <option value="0">Chose Truck</option>
                        {selectOptions}
                    </select>

                    <MapTags tags={this.state.mapMarkers} tagClickHandler={this.tagClickHandler} removeMarker={this.removeMarker} />

                    <div className="truck-form">

                        <div className="input-wrap">
                            <input name="truck_id" type="text" autoComplete="off" placeholder="Truck ID" onChange={this.onChange} />
                        </div>
                        <div className="btns-container">
                            <button className={'btn' + (this.state.isLoading ? ' loading' : '')} onClick={this.showTruckOnMap}>
                                <span>{this.state.isLoading ? ' loading' : 'show'}</span>
                            </button>
                        </div>
                    </div>
                    <div>
                        valid ID: <br/>
                        <span>85A173140107</span>
                    </div>


                    {/*<TruckInfo truckInfo={this.state.truckInfo} />*/}
                </div>

                <div className="right-aside">

                    <MainMap ref={this.mapRef} onMarkerAdded={this.onMarkerAdded} />
                </div>
            </div>
        )
    }
}

export default App;