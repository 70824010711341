import React from "react";
import axios from "axios";

class LogTable extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            logItems: [],
            isLoading: false,
            filter: [
                {
                    name: 'truck_number',
                    value: 23
                },
                {
                    name: 'driver_name',
                    value: 'Jack'
                }
            ],
            sort: {
                name: 'truck_number',
                type: 'DESK'
            }
        };

    }

    onTruckDataReceive(data) {
        if (data) {
            this.setState({
                logItems: data
            })
        }
    }

    componentDidMount() {
        this.getLogInfo();
    }

    getLogInfo () {

        const data = {
            dataProp: 1,
            dataProp2: 2,
        };
        this.setState({
            isLoading: true,
        });
        axios({
            method: 'post',
            data: data,
            url: '/dist/php/get_log_info.php',
        }).then((response) => {
            this.onTruckDataReceive(response.data);
            this.setState({
                isLoading: false,
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    render () {

        let logItems = this.state.logItems.map( (item) => {
            return <tr key={item.id}>
                <td>
                    {item.truck_number}
                </td>
                <td>
                    {item.driver_name}
                </td>
                <td>
                    {item.date}
                </td>
                <td>
                    {item.miles}
                </td>
                <td>
                    {item.location}
                </td>
                <td>
                    {item.state}
                </td>
            </tr>
        });

        return (
            <div className="table">
                {this.state.isLoading ? 'Loading...' : ''}
                <table>
                    <thead>
                        <tr>
                            <td>
                                <div className="text">Truck №</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                            <td>
                                <div className="text">Driver name</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                            <td>
                                <div className="text">Date</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                            <td>
                                <div className="text">Miles</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                            <td>
                                <div className="text">Location</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                            <td>
                                <div className="text">State</div>
                                <div>
                                    <input type="text" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Truck №</td>
                            <td>Driver name</td>
                            <td>Date</td>
                            <td>Miles</td>
                            <td>Location</td>
                            <td>State</td>
                        </tr>
                    </thead>
                    <tbody>
                        {logItems}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default LogTable;