import React from "react";
import Header from "./Header";
import Home from "./Home";
import Log from "./Log";
import { BrowserRouter as Router, Route} from "react-router-dom";

function AppRouter() {
    return (
        <Router>


                <Route path="/dist/" exact component={Home} />
                <Route path="/dist/log.html" component={Log} />

        </Router>
    );
}

export default AppRouter;